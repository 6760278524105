@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Raleway:wght@200&display=swap');

html {
    height: 100%;
    box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #ffffff;
}
.text-muted {
  color: #777777;
}
.text-primary {
  color: #fed136;
}
p {
  font-size: 14px;
  line-height: 1.75;
  font-family: 'Raleway', sans-serif;
}
a,
a:hover,
a:focus,
a:active,
a.active {
  outline: none;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

section {
  position: relative;
  perspective: 10rem;
  overflow: hidden;
  background: linear-gradient(180deg, white, #c1c1c138, white);
}

.absolute {
  position: absolute;
}

.album {
  position: relative;
  right: 11%;
  border: 1px solid #000000;
}

.backGroundPlacement {
  transform: translateZ(-15rem);
  z-index: -1;
  filter: blur(5px);
}

.bold {
  font-weight: bold;
}

.center {
  justify-content: center;
  align-items: center;
}

.half {
  width: 50%;
}

.footer {
  padding: 1rem 0 0.1rem 0;
  border-top: 1px solid #c2c2c2;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
}

.footer > * {
  margin: 0;
}

.full {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.quarter {
  width: 20%;
}

.button {
  background: black;
  color: white;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
}

.button:hover,
.button:focus,
.button:active,
.button.active {
  color: black;
  background: white;
}

.cardDark {
  background: #70707096!important;
  color: #fff;
}

.column {
  display: flex;
  flex-direction: column;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.darken {
  filter: brightness(0.7)
}

.frame {
  overflow: hidden;
  height: 300px;
}

.cardsRow {
  display: flex;
}

.cardsRow > .frame {
  overflow: hidden;
  height: 457px;
  width: 195px;
  transform: rotateY(-6deg) translateZ(-10px);
  border: 2px solid black;
}

.cardsRow > .frame:last-of-type {
  z-index: -1;
  transform: rotateY(-8deg) translateZ(-22px) translateX(30px) translateY(-1px);
  height: 505px;
}

.frame > img {
  height: 100%;
  width: 100%;
}

.headerText {
  margin-top: 2rem;
  width: 100%;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.infoCard {
  padding: 2rem;
  border-radius: 4px;
  text-align: center;
}

.infoCard > p {
  font-size: 1.4rem;
}

.logoButterfly {
  height: 50px;
  width: 50px;
  margin-bottom: 10px;
}

.logoInsta {
  height: 21px!important;
}

.logoSmall {
  height: 65px;
  width: 65px;
}

.logoSmaller {
  height: 25px;
  width: 25px;
  filter: invert(1);
}

.marginAuto {
  margin: auto;
}

.nav {
  padding: 1rem 0 0.1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
}

.nav > * {
  margin: 0;
}

.profileColumn {
  padding: 1rem;
  background: #000000d4;
  padding: 2rem;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.profileColumn > p {
  color: #fff;
  width: 100%;
  font-size: 1.6rem;
  margin: 0.5rem 0;
  line-height: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.profileColumn > p > span.valueText {
}

.row{
  display: flex;
}

.spaceAround {
  justify-content: space-around;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.textSmaller {
  font-size: 1.5rem!important;
}

.whiteBG {
  background: #fff;
}

.whiteBG > p {
  color: #000;
}






.entranceBackground {
    width: 48%;
    min-width: 330px;
}

.entranceLine {
    border-top: 2px solid #272727;
    border-radius: 50%;
    width: 40%;
    min-width: 330px;
    background: white;
}

.entranceWords0 {
    width: 15%;
    min-width: 150px;
    position: absolute;
    bottom: 0;
}

.entranceWords1 {
    width: 55%;
    min-width: 150px;
    position: absolute;
    bottom: 0;
    z-index: 1;
    transform: rotateY(90deg);
}

.sied {
    position: fixed;
    top: 50px;
    width: 12%;
    min-width: 80px;
    cursor: pointer;
}

.console {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  bottom: 5%;
}

.console form {
  width: 100%;
}

.console input {
  border: none;
  background: transparent;
  outline: none;
  width: 100%;
  padding: 0 1rem;
  font-size: 1.5rem;
  color: #686868;
}

.console p {
  font-size: 20px;
}

main {
  overflow: hidden;
}

.sied-container {
  position: relative;
  perspective: 10rem;
  overflow: hidden;
  background: linear-gradient(180deg, white, #c1c1c138, white);
}

.sied-container-body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.sied-container-images {
  position: absolute;
  top: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}





/* MEDIA QUERIES */

@media (max-width: 700px) {

  p {
    text-align: center;
  }

  .album {
    right: 0%;
    top: -9rem;
  }

  .button {
    text-align: center;
  }

  .cardsRow > .frame:last-of-type {
    transform: rotateY(-8deg) translateZ(-49px) translateX(13px) translateY(-1px);
  }

  .cardsRow > .frame {
    transform: rotateY(-6deg) translateZ(-28px);
  }

  .containerButtons .button {
    margin: 1rem 0;
  }

  .firstCard {
    line-height: 1.6rem;
  }

  .half {
    width: 100%;
  }

  .headerPic {
    min-height: 250px;
  }

  .infoCard > p {
    font-size: 1.2rem;
  }

  .logoDot {
    transform: scale(1)!important;
  }

  .profileColumn > p {
    font-size: 1.4rem;
  }

  .profileColumn > p > span.valueText {
    font-size: 1rem;
  }
  
  .row {
    flex-direction: column;
  }

  .textSmaller {
    font-size: 1.1rem!important;
  }
}




/* ANIMATION CODE */


.borderSwitch {
  border-radius: 0;
  width: 100%;
  border-top: 1px solid #272727;
}

.rotateInvisible {
  transform: rotateY(90deg);
}

.rotateVisible {
  transform: rotateY(0deg);
}

.noDisplay {
  display: none;
}

.display {
  display: initial;
}

.inVisible {
  opacity: 0;
}

.visible {
  opacity: 1;
}



* {
  transition: all 1s;
  box-sizing: border-box;
}


/* ANIMATIONS */ 

.animate {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.movement {
  animation: movement 5s infinite;
}

@keyframes movement {
  0% {
    transform: translate3d(0px, 0px, -6rem);
  }
  50% {
    transform: translate3d(0px, 0px, -5rem) rotatey(2deg);
  }
  100% {
    transform: translate3d(0px, 0px, -6rem);
  }
}

.movement0 {
  animation: movement0 7s infinite;
}

@keyframes movement0 {
  0% {
    transform: translate3d(0px, 5px, -7rem);
  }
  25% {
    transform: translate3d(0px, 0px, -6.5rem) rotatey(2deg);
  }
  75% {
    transform: translate3d(0px, 0px, -6.5rem) rotatey(-2deg);
  }
  100% {
    transform: translate3d(0px, 5px, -7rem);
  }
}

.introFadeIn {
  animation: introFadeIn 1s;
}

@keyframes introFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.introFadeOut {
  animation: introFadeOut 1s;
}

@keyframes introFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.aboutFadeIn {
  -webkit-animation: introFadeIn 0.5s;
  animation: introFadeIn 0.5s;
}

@keyframes introFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.aboutFadeOut {
  -webkit-animation: introFadeOut 0.5s;
  animation: introFadeOut 0.5s;
}

@keyframes introFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}




.hover {
  -webkit-animation: hover ease 4s infinite;
  animation: hover ease 4s infinite;
}

.hovered {
  animation-iteration-count: 1;
}

@keyframes hover {
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: 50px;
  }
  100% {
    margin-top: 0px;
  }
}





/* LOGO DOTS CODE */

#headerLogo {
  width: 100%;
  max-width:20rem;
  position: fixed;
  top: 3rem;
  animation: focusIn 1s linear 0s 1 forwards;
}

.logoBanner {
  display: flex;
    width: 320px;
    height: 5px;
    position: fixed;
    justify-content: center;
    top: 269px;
}

.logoDotContainer {
  width: 100%;
  max-width: 300px;
  height: 100px;
  position: fixed;
  left: 18%;
  bottom: -5%;
  opacity: 0;
  animation: logoHover 2s infinite;
}

.logoDotContainerRight {
  width: 100%;
  max-width: 300px;
  height: 100px;
  position: fixed;
  right: 0%;
  bottom: -5%;
  opacity: 0;
  animation: logoHover 2s infinite;
}

.logoDotContainerTop {
  opacity: 0;
  width: 100%;
  max-width: 300px;
  height: 100px;
  position: fixed;
  top: 59%;
  right: 3%;
  animation: logoHoverTop 2s infinite;
}

.logoDotContainerTopLeft {
  opacity: 0;
  width: 100%;
  max-width: 300px;
  height: 100px;
  position: fixed;
  top: 5%;
  left: 19%;
  animation: logoHoverTop 2s infinite;
  transform: rotate(290deg);
}

.logoDot {
  position: absolute;
  width: 4px;
  height: 4px;
  background: #a4eced;
  z-index: 1000;
  border-radius: 100%;
  transform: scale(2.5);
}

.logoDotLowerLeft {
  bottom: 115px;
    left: 60px;
    box-shadow: 10px 2px 0px #a4eced;
}

.logoDotLowerLeft:nth-of-type(2) {
  bottom: 135px;
    left: 88px;
    box-shadow: -20px 4px 0px #a4eced;
}

.logoDotTopLeft {
  bottom: 206px;
    left: 85px;
    box-shadow: -7px -8px 0px #a4eced;
}

.logoDotLowerRight {
  bottom: 110px;
    right: 53px;
    box-shadow: 6px 8px 0px #a4eced;
}

.logoDotTopRight {
  bottom: 190px;
    right: 26px;
    box-shadow: -1px -12px 0px #a4eced;
}


@keyframes logoHover {
  0% {margin-top:0px;opacity:0;}
  15% {opacity:1;}
  70% {opacity:1;}
  100% {margin-top:10px; opacity: 0}
}

@keyframes logoHoverTop {
  0% {margin-top:0px;opacity:0;}
  15% {opacity:1;}
  70% {opacity:1;}
  100% {margin-top:-10px; opacity: 0}
}